.loading.cover-content {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.loading.cover-page {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.loading .ant-spin {
	color: #062d10;
}
.label {
	margin-bottom: 6px;
}
.label label {
	font-weight: 600;
}
